<template>
  <section class="stakingSection my-3">
    <dl class="mb-0">
      <dt>{{ $t("message.mef2") }}</dt>
      <dd class="mt-2">
        <p>
          <span>
            <strong class="numberFont">
              {{ contractStore.showEarn }}
              <!-- {{contractStore.showEarn}} -->
            </strong></span
          >
          <span>DLP</span>
          <QuestionMark
            @click.stop.native="$emit('showMsgBoxTwo', $t('message.mef4'))"
          />
        </p>
        <p>
          <span class="iconColor1 numberFont">
            <small> ~ $ {{ contractStore.dollarEarn }} </small>
            <!-- <small> ~ $  {{  contractStore.dollarEarn}}</small> -->
          </span>
        </p>
      </dd>
    </dl>
  </section>
</template>

<script>
import QuestionMark from "./Question.vue";
import { useContractStore } from "../stores/contractView";

export default {
  name: "Claimable",
  emits: ["showMsgBoxTwo"],
  components: { QuestionMark },
  setup() {
    const contractStore = useContractStore();
    return {
      contractStore,
    };
  },
};
</script>
<style scoped>
.stakingSection {
  padding: 0rem 0.5rem;
  border-radius: 4px;
  background: #ffde5f24;
}
.numberFont {
  font-family: "Spoqa Han Sans Neo", "sans-serif";
}
@media screen and (max-width: 1023px) {
  .stakingSection {
    padding: 20px 10px;
  }
}
@media screen and (max-width: 768px) {
  .stakingSection {
    padding: 15px 8px;
    margin-top: 1rem;
  }
}
</style>
