<template>
  <div class="d-flex size100 justify-content-center me-2">
    <b-row class="rowBorder borderRadius smText1 size100">
      <b-col cols="9" v-if="grade != 0">{{ seeJoinMembership }}</b-col>
      <b-col cols="9" v-else class="iconColor1"
        >{{ $t("message.newThird1") }}
        <!-- ({{ waiting }}
                              {{ setLanguage.newThird0 }}) -->
      </b-col>
      <b-col cols="3" class="tableRight"><strong> DLP</strong></b-col>
    </b-row>
    <slot name="hello1234"></slot>
  </div>
</template>

<script>
export default {
  name: "TokenAmount",
  props: {
    grade: String,
    seeJoinMembership: String,
  },
};
</script>
<style scoped>
.size100 {
  width: 100%;
}
</style>
