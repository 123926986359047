<template>
  <div class="pc-marginT-5">
    <!-- 카드 시작 -->
    <MainCardFront :key="bannerStore.cardKey" />
    <!-- 카드 끝 -->
    <!-- <div v-show="pageStore.nextPage === 0">
      공지 카르셀
      <MainFrstNotion />
      메인화면 정보
      <div>
        <div class="justify-content-center m-0 row">
          <div class="col-xl-9 col-lg-9 col-md-9 col-sm-10 col-xs-12">
            <ProgressM />
            <Detail
              class="mb-5"
              @showMsgBoxTwo="showMsgBoxTwo"
              :myPage="true"
            />
          </div>
        </div>
      </div>
    </div> -->
    <!-- 메인화면의 디테일한 정보 끝 -->
    <Layout>
      <div slot="layout">
        <section>
          <div v-show="pageStore.nextPage === 1" class="mb-5">
            <div>
              <!-- 가입하고자 하는 멤버십 선택 -->
              <b-form-radio-group
                v-model="grade"
                :options="contractStore.options"
                :disabled="
                  contractStore.contractAllowance < 50000000000000000000000
                "
                class="d-flex justify-content-between"
                value-field="item"
                text-field="name"
                disabled-field="notEnabled"
                plain
              ></b-form-radio-group>
              <!-- 가입하고자 하는 멤버십 선택 끝 -->

              <div class="d-flex justify-content-between mt-3">
                <!-- 원하는 멤버십에 가입하려면 지불해야하는 토큰의 양 -->
                <MainStakeTokenAmount
                  :grade="grade"
                  :seeJoinMembership="seeJoinMembership"
                />
                <!-- 원하는 멤버십에 가입하려면 지불해야하는 토큰의 양 끝 -->

                <!-- 승인 및 멤버쉽 업그레이드 버튼 -->
                <div
                  class="d-flex text-center align-items-center buttonRadius1 buttonFontSize"
                >
                  <!-- 어프로브 -->
                  <BasicButtonLong
                    v-if="
                      contractStore.contractAllowance < 50000000000000000000000
                    "
                    @click.native="showMsgBoxOne($t('message.help7'), 0)"
                    class="sizee7 buttonFontSize"
                    :text="$t('message.newThird3')"
                  />
                  <!-- 어프로브 끝 -->
                  <!-- @click.native="approve(testMembership)" -->
                  <!-- 토큰부족  -->
                  <BasicButtonLong
                    v-else-if="contractStore.myTokenBalance < joinMembership"
                    class="sizee7 buttonFontSize"
                    disabled
                    :text="$t('message.newThird12')"
                  />
                  <!-- 토큰부족 끝-->
                  <!-- 가입 및 업그레이드 -->
                  <BasicButtonLong
                    v-else
                    @click.native="
                      contractStore.nowGrade === 'none'
                        ? showMsgBoxOne($t('message.help28'), 1)
                        : showMsgBoxOne($t('message.help8'), 1)
                    "
                    class="sizee7 buttonFontSize"
                    :disabled="grade == null"
                    :text="
                      !contractStore.upgradeUser
                        ? $t('message.newSide3')
                        : $t('message.newThird4')
                    "
                  />
                  <!-- 가입 및 업그레이드 -->
                </div>
                <!-- 승인 및 멤버쉽 업그레이드 버튼 끝 -->
              </div>

              <!-- 업그레이 관련 설명  -->
              <p
                class="mt-2 iconColor1 mb-4 smText"
                v-if="contractStore.contractAllowance < 50000000000000000000000"
              >
                {{ $t("message.newThird11") }}
              </p>
              <p v-else class="mt-2 iconColor1 mb-4 smText">
                {{ $t("message.newThird5") }}
              </p>
              <!-- 업그레이 관련 설명  끝-->

              <!-- 멤버쉽 설명 -->
              <MembershipTiers @showMsgBoxTwo="showMsgBoxTwo" />

              <MaingStakeHelpMsg class="smText" />
              <!-- 멤버쉽 설명 끝-->
            </div>
          </div>
        </section>
        <section>
          <div v-show="pageStore.nextPage === 2" class="mb-5">
            <!-- 멤버쉽 해지 화면 스테이킹 상태 디테일 -->
            <h4 class="detailTop mx-2">
              <strong> {{ $t("message.newSecond1") }} </strong>
            </h4>

            <Detail @showMsgBoxTwo="showMsgBoxTwo" :myPage="false" />

            <!-- 멤버쉽 해지 화면 스테이킹 상태 디테일 끝 -->

            <!-- 멤버쉽 해지 버튼 -->
            <div
              class="text-center align-items-center buttonRadius1 d-block"
              v-if="contractStore.nowSeconds > contractStore.daySeconds"
            >
              <StakingClaimButton
                @click.native="showMsgBoxOne($t('message.help11'), 4)"
                :nowGrade="contractStore.nowGrade"
                :text1="$t('message.newThird10')"
              />
              <br />
              <BasicButtonLong
                @click.native="showMsgBoxOne($t('message.help9'), 2)"
                class="mt-1"
                :disabled="
                  contractStore.nowGrade == 'none' ||
                  contractStore.nowSeconds < contractStore.will1
                "
                :text="$t('message.third15')"
              />

              <p class="mt-2 iconColor1">
                {{ $t("message.newThird6") }}
                <!-- <br /> -->
                {{ $t("message.newThird7") }}
              </p>
            </div>

            <!-- 멤버쉽 해지 버튼 끝 -->
            <!-- 24시간 이전(기다리는 시간이 24시간보다 작으면 꼬임. 180일 기준) -->
            <div
              class="text-center align-items-center buttonRadius1 d-block"
              v-else
            >
              <StakingClaimButton
                @click.native="showMsgBoxOne($t('message.help11'), 4)"
                :nowGrade="contractStore.nowGrade"
                :text1="$t('message.newThird10')"
              />
              <br />
              <!-- v-if="업그레이드 한적 없는 사용자" -->
              <BasicButtonLong
                v-if="contractStore.upgradeUser == false"
                @click.native="showMsgBoxOne($t('message.help10'), 3)"
                class="mt-1"
                :disabled="contractStore.nowGrade == 'none'"
                :text="`${$t('message.third15')}(penalty)`"
              />
              <BasicButtonLong
                v-else
                @click.native="showMsgBoxOne($t('message.help9'), 2)"
                class="mt-1"
                :disabled="
                  contractStore.nowGrade == 'none' ||
                  contractStore.nowSeconds < contractStore.will1
                "
                :text="$t('message.third15')"
              />
              <p class="mt-2 iconColor1 smText">
                {{ $t("message.newThird8") }}
                <br />
                {{ $t("message.newThird9") }}
              </p>
            </div>
            <!-- 24시간 이전 끝 -->
          </div>
        </section>

        <!-- 해지 페이지 끝 -->
      </div>
    </Layout>
    <KlipModal :klipC="klipC" :canvas="canvas" @klipChange="klipChange" />
    <Footer class="pc-marginT-5" />
  </div>
</template>
<script>
import modalData from "../assets/data/modal.json";
import BasicButtonLong from "../components/basicUse/BasicButtonLong.vue";
import StakingClaimButton from "../components/basicUse/StakingClaimButton.vue";
import MainCardFront from "../components/mainCard/Front.vue";
// import MainFrstNotion from "../components/mainFirst/Notion.vue";
// import ProgressM from "../components/mainFirst/ProgressM.vue";
import MainStakeTokenAmount from "../components/mainStake/TokenAmount.vue";
import MaingStakeHelpMsg from "../components/mainStake/HelpMsg.vue";
import MembershipTiers from "../components/table/MembershipTiers.vue";
import Detail from "../components/table/Detail.vue";
import Layout from "../components/basicUse/Layout.vue";
import KlipModal from "../components/klip/KlipModal.vue";

import { request } from "klip-sdk";
import { useAccountStore } from "../stores/account";
import { usePageStore } from "../stores/nextPage";
import { useAdminStore } from "../stores/adminCheck";
import { useContractStore } from "../stores/contractView";
import { useBannerStore } from "../stores/banner";
import { useWidthStore } from "../stores/width";
import { membership } from "../control/contractData";
import { fromWei, BN } from "../control/fromWei";
import { apiKlip } from "../control/axiosKlip";
import { getRemainDay, getNowTime, timeSetRS } from "../control/time";
import {
  approveTx,
  beforeDepositTx,
  beforeWithdrawalTx,
  beforeWithdrawal24Tx,
  stakingClaimTx,
} from "../control/sendTx";
import { getUserInfo } from "../control/contractMembership";
import { allowance } from "../control/contractToken";
import {
  mobile,
  klipApprove,
  klipClaim,
  klipDeposit,
  klipWithdrawal,
  klipWithdrawal24,
} from "../control/klip";
import Footer from "../components/basicUse/footer.vue";

export default {
  name: "Membership",
  components: {
    BasicButtonLong,
    StakingClaimButton,
    MainCardFront,
    // MainFrstNotion,
    MainStakeTokenAmount,
    MaingStakeHelpMsg,
    MembershipTiers,
    Layout,
    Detail,
    // ProgressM,
    KlipModal,
    Footer,
  },
  data() {
    return {
      fromWei,
      BN,
      getRemainDay,
      getUserInfo,
      allowance,
      getNowTime,
      modalData,
      membership,
      timeSetRS,
      approveTx,
      beforeDepositTx,
      beforeWithdrawalTx,
      beforeWithdrawal24Tx,
      stakingClaimTx,
      mobile,
      request,
      apiKlip,
      klipTx: [
        klipApprove,
        klipDeposit,
        klipWithdrawal,
        klipWithdrawal24,
        klipClaim,
      ],
      depositFee: null,
      grade: null,
      joinMembership: null,
      seeJoinMembership: null,
      gradeToImage: "card5.png",
      NumberJoinMembership: null,
      klipC: false,
      canvas: null,
      timer: null,
    };
  },
  watch: {
    // 내 멤버쉽 등급에 따라, 원하는 등급이 되고 싶을 때, 스테이킹 해야 할 가격 계산
    async grade(val) {
      if (!val) return;
      this.switchFee(this.grade);
      //지수표현식으로 나와서 빅넘버 사용
      this.calculateJoin();
    },
  },
  async mounted() {
    if (this.accountStore.account !== "") {
      // 방문한적 없음
      if (!this.pageStore.membershipFV) {
        this.pageStore.setMembershipVisit();
        await this.contractStore.start(this.accountStore.account);
      } else {
        this.contractStore.setRefresh();
      }
    }
    this.accountStore.$subscribe(async () => {
      if (this.$route.path === "/membership") {
        if (!this.pageStore.membershipFV) {
          if (this.accountStore.account !== "") {
            this.pageStore.setMembershipVisit();
            await this.contractStore.start(this.accountStore.account);
          }
        }
      }
    });
  },
  destroyed() {
    // clearInterval(this.refresh);
    this.contractStore.delRefresh();
    clearInterval(this.timer);
  },
  setup() {
    const accountStore = useAccountStore();
    const pageStore = usePageStore();
    const adminStore = useAdminStore();
    const contractStore = useContractStore();
    const bannerStore = useBannerStore();
    const widthStore = useWidthStore();
    return {
      accountStore,
      pageStore,
      adminStore,
      contractStore,
      bannerStore,
      widthStore,
    };
  },
  methods: {
    // 등급별 가격 확인
    switchFee(grade) {
      const numGrade = Number(grade);
      this.depositFee = this.contractStore.depositFee50[numGrade];
    },

    // 지불해야하는 금액 계산
    calculateJoin() {
      let a = this.BN(this.depositFee);
      let b = this.BN(this.contractStore.addressToDeposit);
      let money = a.sub(b).toString();
      // 계산 결과가 음수인지 확인
      if (money.slice(0, 1) == "-") {
        money = "0";
      }
      this.seeJoinMembership = this.fromWei(money);
      this.joinMembership = money;
      this.NumberJoinMembership = Number(money);
    },

    // --- 이벤트 ---

    //--------------------- 트랜잭션 발생 -------------------------

    //토큰 사용 승인 요청
    async approve(spender) {
      // 메타마스크 or 카이카스
      if (this.contractStore.isMetamask) {
        try {
          const wallet = await this.approveTx(spender);
          const allow = await this.allowance(
            spender,
            this.accountStore.account,
            wallet
          );
          this.contractStore.setAllow(allow);
        } catch (error) {
          this.showMsgBoxTwo("fail");
        }
      } else {
        await this.sendKlip(0, spender, null, null);
      }
    },

    //스테이킹
    async beforeDeposit() {
      // 메타마스크 or 카이카스
      if (this.contractStore.isMetamask) {
        try {
          const result = await this.beforeDepositTx(
            this.grade,
            this.joinMembership
          );
          await this.deposit(result[1], result[2]);
        } catch (error) {
          this.showMsgBoxTwo(this.$t("message.help24"));
        }
      } else {
        await this.sendKlip(1, null, this.grade, this.joinMembership);
      }
    },

    async deposit(transactionHash, wallet) {
      const allow = await this.allowance(
        this.membership,
        this.accountStore.account,
        wallet
      );
      this.contractStore.setAllow(allow);

      const userInfo = await this.contractStore.beforeTx(
        wallet,
        this.accountStore.account
      );

      this.hashScan(
        transactionHash,
        this.$t("message.help22") +
          this.contractStore.nowGrade +
          this.$t("message.help23")
      );

      this.switchFee(this.grade);
      this.calculateJoin();
      this.contractStore.radioControl();

      this.grade = null;

      await this.contractStore.afterTx(
        userInfo,
        wallet,
        this.accountStore.account
      );
    },

    // 스테이킹된 돈 빼내기
    async beforeWithdrawal() {
      // 메타마스크 or 카이카스
      if (this.contractStore.isMetamask) {
        try {
          const result = await this.beforeWithdrawalTx();

          this.hashScan(result[1], this.$t("message.help21"));
          await this.withdrawal(result[2]);
        } catch (error) {
          this.showMsgBoxTwo(this.$t("message.help20"));
        }
      } else {
        await this.sendKlip(2, null, null, null);
      }
    },

    async beforeWithdrawal24() {
      const wallet = sessionStorage.getItem("wallet");
      // 나우 세컨즈만 구하면 됨 (예외처리 위해서)
      this.contractStore.setNowSeconds(await this.getNowTime(wallet));

      if (this.contractStore.nowSeconds > this.contractStore.daySeconds) {
        this.showMsgBoxTwo(this.$t("message.help6"));
      } else {
        // 메타마스크 or 카이카스
        if (this.contractStore.isMetamask) {
          try {
            const result = await this.beforeWithdrawal24Tx();
            this.hashScan(result[1], this.$t("message.help21"));
            await this.withdrawal(result[2]);
          } catch (error) {
            this.showMsgBoxTwo(this.$t("message.help20"));
          }
        } else {
          await this.sendKlip(3, null, null, null);
        }
      }
    },

    async withdrawal(wallet) {
      const userInfo = await this.contractStore.beforeTx(
        wallet,
        this.accountStore.account
      );

      this.calculateJoin();
      this.contractStore.radioControl();

      await this.contractStore.afterTx(
        userInfo,
        wallet,
        this.accountStore.account
      );

      // clearInterval(this.refresh);
      this.contractStore.delRefresh();
    },

    async stakingClaim() {
      // 메타마스크 or 카이카스
      if (this.contractStore.isMetamask) {
        try {
          const result = await this.stakingClaimTx();
          this.hashScan(result[1], this.$t("message.help17"));
          await this.afterStakingClaim(result[2]);
        } catch (error) {
          this.showMsgBoxTwo(this.$t("message.help18"));
        }
      } else {
        await this.sendKlip(4, null, null, null);
      }
    },

    async afterStakingClaim(wallet) {
      const userInfo = await this.getUserInfo(
        this.accountStore.account,
        wallet
      );

      this.contractStore.setNowSeconds(await this.getNowTime(wallet));
      const result1 = this.getRemainDay(
        this.contractStore.stakingTime,
        this.contractStore.nowSeconds,
        this.contractStore.waiting
      );

      const time1 = this.timeSetRS(result1, this.contractStore.waitingDay);
      this.contractStore.setRemainTime(time1[0]);
      this.contractStore.setSeeRemain(time1[1]);

      await this.contractStore.afterTx(
        userInfo,
        wallet,
        this.accountStore.account
      );
    },

    //--------------------- 트랜잭션 발생 -------------------------

    // bsc 스캔 a태그 만들기
    hashScan(transactionHash, msg) {
      // createElement 를 못찾아서 따로 안빼둠
      // createElement: vue 내장 render 함수
      const h = this.$createElement;
      const hashShort =
        transactionHash.slice(0, 15) + " ... " + transactionHash.slice(53, 66);

      const txhash = h("div", [
        h("div", msg),
        // h("br"),
        h("span", this.$t("message.help19")),
        h("a", {
          domProps: {
            innerHTML: hashShort,
            href: `https://scope.klaytn.com/tx/${transactionHash}`,
            target: "_blank",
          },
        }),
      ]);
      this.showMsgBoxTwo(txhash);
    },

    // 모달창 ------------------------------------------------
    // this$bvModal을 못찾아서 여기둠
    showMsgBoxTwo(msg) {
      let modal = this.modalData;
      modal.okTitle = this.$t("message.mef7");
      this.$bvModal.msgBoxOk(msg, modal);
    },

    showMsgBoxOne(msg, num) {
      let modal = this.modalData;
      modal.okTitle = this.$t("message.mef7");
      modal.cancelTitle = this.$t("message.mef8");
      modal.cancelVariant = "danger";
      this.$bvModal.msgBoxConfirm(msg, modal).then((value) => {
        if (value) {
          switch (num) {
            case 0:
              if (this.accountStore.account === "") {
                alert("Connect wallet plz");
              } else {
                this.approve(this.membership);
              }
              break;
            case 1:
              this.beforeDeposit();
              break;
            case 2:
              this.beforeWithdrawal();
              break;
            case 3:
              this.beforeWithdrawal24();
              break;
            case 4:
              this.stakingClaim();
              break;
          }
        }
      });
    },

    async sendKlip(num, spender, newGrade, newAmount) {
      let res;
      if (num === 0) {
        res = await this.klipTx[num]("DLP MEMBERSHIP", spender);
      } else if (num === 1) {
        res = await this.klipTx[num](newGrade, newAmount);
      } else {
        res = await this.klipTx[num]();
      }

      if (!res.state) {
        this.showMsgBoxTwo("승인 실패");
      } else {
        // 모바일아니면 캔버스 스캔할 수 있도록 뛰어줘야함
        if (!this.mobile()) {
          this.klipC = true;
          this.canvas = res.canvas;
        } else {
          this.request(res.key, () => alert("mobile plz"));
        }
        // pc, 모바일 둘 다 응답 기다려야함
        this.klipWait(res.key, num, spender);
      }
    },

    klipWait(key, num, spender) {
      this.timer = setInterval(async () => {
        const res = await this.apiKlip(
          `https://a2a-api.klipwallet.com/v2/a2a/result?request_key=${key}`
        );
        if (res.data.status === "completed") {
          clearInterval(this.timer);
          this.klipC = false;
          if (res.data.result.status === "success") {
            await this.scanAndAfter(num, res.data.result.tx_hash, spender);
          } else {
            this.hashScan(res.data.result.tx_hash, "fail");
          }
        }
      }, 2100);
    },

    async scanAndAfter(num, tx, spender) {
      let allow;
      switch (num) {
        case 0:
          //approve
          allow = await this.allowance(
            spender,
            this.accountStore.account,
            "true"
          );
          this.contractStore.setAllow(allow);
          break;
        case 1:
          //deposit
          await this.deposit(tx, "true");
          break;
        case 2:
          //with
          this.hashScan(tx, this.$t("message.help21"));
          await this.withdrawal("true");
          break;
        case 3:
          //with24
          this.hashScan(tx, this.$t("message.help21"));
          await this.withdrawal("true");
          break;
        case 4:
          //claim
          this.hashScan(tx, this.$t("message.help17"));
          await this.afterStakingClaim("true");
          break;
      }
    },

    klipChange(msg) {
      this.klipC = msg;
    },
  },
};
</script>
<style scoped>
.fontTable {
  font-size: 16px;
}
.sizee7 {
  width: 7rem !important;
}
@media screen and (max-width: 1023px) {
}
@media screen and (max-width: 768px) {
  .fontTable {
    font-size: 12px;
  }
  .buttonFontSize {
    font-size: 13px !important;
  }
  .sizee7 {
    width: 6rem !important;
  }
}
@media screen and (max-width: 374px) {
  .buttonFontSize {
    font-size: 12px !important;
  }
  .sizee7 {
    width: 5.4rem !important;
  }
}
</style>
