import { render, staticRenderFns } from "./StakingClaimButton.vue?vue&type=template&id=817f6bee&scoped=true&"
import script from "./StakingClaimButton.vue?vue&type=script&lang=js&"
export * from "./StakingClaimButton.vue?vue&type=script&lang=js&"
import style0 from "./StakingClaimButton.vue?vue&type=style&index=0&id=817f6bee&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "817f6bee",
  null
  
)

export default component.exports