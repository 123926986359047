var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pc-marginT-5"},[_c('MainCardFront',{key:_vm.bannerStore.cardKey}),_c('Layout',[_c('div',{attrs:{"slot":"layout"},slot:"layout"},[_c('section',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pageStore.nextPage === 1),expression:"pageStore.nextPage === 1"}],staticClass:"mb-5"},[_c('div',[_c('b-form-radio-group',{staticClass:"d-flex justify-content-between",attrs:{"options":_vm.contractStore.options,"disabled":_vm.contractStore.contractAllowance < 50000000000000000000000,"value-field":"item","text-field":"name","disabled-field":"notEnabled","plain":""},model:{value:(_vm.grade),callback:function ($$v) {_vm.grade=$$v},expression:"grade"}}),_c('div',{staticClass:"d-flex justify-content-between mt-3"},[_c('MainStakeTokenAmount',{attrs:{"grade":_vm.grade,"seeJoinMembership":_vm.seeJoinMembership}}),_c('div',{staticClass:"d-flex text-center align-items-center buttonRadius1 buttonFontSize"},[(
                    _vm.contractStore.contractAllowance < 50000000000000000000000
                  )?_c('BasicButtonLong',{staticClass:"sizee7 buttonFontSize",attrs:{"text":_vm.$t('message.newThird3')},nativeOn:{"click":function($event){_vm.showMsgBoxOne(_vm.$t('message.help7'), 0)}}}):(_vm.contractStore.myTokenBalance < _vm.joinMembership)?_c('BasicButtonLong',{staticClass:"sizee7 buttonFontSize",attrs:{"disabled":"","text":_vm.$t('message.newThird12')}}):_c('BasicButtonLong',{staticClass:"sizee7 buttonFontSize",attrs:{"disabled":_vm.grade == null,"text":!_vm.contractStore.upgradeUser
                      ? _vm.$t('message.newSide3')
                      : _vm.$t('message.newThird4')},nativeOn:{"click":function($event){_vm.contractStore.nowGrade === 'none'
                      ? _vm.showMsgBoxOne(_vm.$t('message.help28'), 1)
                      : _vm.showMsgBoxOne(_vm.$t('message.help8'), 1)}}})],1)],1),(_vm.contractStore.contractAllowance < 50000000000000000000000)?_c('p',{staticClass:"mt-2 iconColor1 mb-4 smText"},[_vm._v(" "+_vm._s(_vm.$t("message.newThird11"))+" ")]):_c('p',{staticClass:"mt-2 iconColor1 mb-4 smText"},[_vm._v(" "+_vm._s(_vm.$t("message.newThird5"))+" ")]),_c('MembershipTiers',{on:{"showMsgBoxTwo":_vm.showMsgBoxTwo}}),_c('MaingStakeHelpMsg',{staticClass:"smText"})],1)])]),_c('section',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pageStore.nextPage === 2),expression:"pageStore.nextPage === 2"}],staticClass:"mb-5"},[_c('h4',{staticClass:"detailTop mx-2"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t("message.newSecond1"))+" ")])]),_c('Detail',{attrs:{"myPage":false},on:{"showMsgBoxTwo":_vm.showMsgBoxTwo}}),(_vm.contractStore.nowSeconds > _vm.contractStore.daySeconds)?_c('div',{staticClass:"text-center align-items-center buttonRadius1 d-block"},[_c('StakingClaimButton',{attrs:{"nowGrade":_vm.contractStore.nowGrade,"text1":_vm.$t('message.newThird10')},nativeOn:{"click":function($event){_vm.showMsgBoxOne(_vm.$t('message.help11'), 4)}}}),_c('br'),_c('BasicButtonLong',{staticClass:"mt-1",attrs:{"disabled":_vm.contractStore.nowGrade == 'none' ||
                _vm.contractStore.nowSeconds < _vm.contractStore.will1,"text":_vm.$t('message.third15')},nativeOn:{"click":function($event){_vm.showMsgBoxOne(_vm.$t('message.help9'), 2)}}}),_c('p',{staticClass:"mt-2 iconColor1"},[_vm._v(" "+_vm._s(_vm.$t("message.newThird6"))+" "),_vm._v(" "+_vm._s(_vm.$t("message.newThird7"))+" ")])],1):_c('div',{staticClass:"text-center align-items-center buttonRadius1 d-block"},[_c('StakingClaimButton',{attrs:{"nowGrade":_vm.contractStore.nowGrade,"text1":_vm.$t('message.newThird10')},nativeOn:{"click":function($event){_vm.showMsgBoxOne(_vm.$t('message.help11'), 4)}}}),_c('br'),(_vm.contractStore.upgradeUser == false)?_c('BasicButtonLong',{staticClass:"mt-1",attrs:{"disabled":_vm.contractStore.nowGrade == 'none',"text":((_vm.$t('message.third15')) + "(penalty)")},nativeOn:{"click":function($event){_vm.showMsgBoxOne(_vm.$t('message.help10'), 3)}}}):_c('BasicButtonLong',{staticClass:"mt-1",attrs:{"disabled":_vm.contractStore.nowGrade == 'none' ||
                _vm.contractStore.nowSeconds < _vm.contractStore.will1,"text":_vm.$t('message.third15')},nativeOn:{"click":function($event){_vm.showMsgBoxOne(_vm.$t('message.help9'), 2)}}}),_c('p',{staticClass:"mt-2 iconColor1 smText"},[_vm._v(" "+_vm._s(_vm.$t("message.newThird8"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("message.newThird9"))+" ")])],1)],1)])])]),_c('KlipModal',{attrs:{"klipC":_vm.klipC,"canvas":_vm.canvas},on:{"klipChange":_vm.klipChange}}),_c('Footer',{staticClass:"pc-marginT-5"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }