<template>
  <b-button
    :disabled="nowGrade == 'none'"
    class="smButton8 mt-4 buttonOutsetColor"
    >{{ text1 }}</b-button
  >
</template>
<script>
export default {
  name: "StakingClaimButton",
  props: {
    nowGrade: String,
    text1: String,
  },
};
</script>
<style scoped>
.buttonOutsetColor {
  color: #ffde5f !important;
  border-color: #ffde5f !important;
  background-color: white !important;
}
</style>
