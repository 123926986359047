<template>
  <b-table-simple class="fontSizeSm sizee border">
    <thead class="tableHe text-center">
      <tr>
        <th>{{ $t("message.help1") }}</th>
        <th class="tableW">
          {{ $t("message.dlp1") }}
          {{ $t("message.dlp2") }}
        </th>
        <th class="tableW">
          {{ $t("message.dlp3") }}
          {{ $t("message.dlp4") }}
        </th>
        <th class="tableW">
          {{ $t("message.dlp5") }}
          {{ $t("message.dlp6") }}
        </th>
      </tr>
    </thead>
    <tbody class="text-center align-middle">
      <tr>
        <td class="tableTTH align-middle">
          {{ $t("message.dlp8") }}
          <QuestionMark
            @click.stop.native="$emit('showMsgBoxTwo', $t('message.dlpHelp1'))"
            class="imgS"
          />
        </td>
        <td>$30,000</td>
        <td>$3,000</td>
        <td>$300</td>
      </tr>
      <tr>
        <td class="tableTTH">
          {{ $t("message.help3") }}
          <QuestionMark
            @click.stop.native="$emit('showMsgBoxTwo', $t('message.dlpHelp2'))"
            class="imgS"
          />
        </td>
        <td class="align-middle">10%</td>
        <td class="align-middle">8%</td>
        <td class="align-middle">6%</td>
      </tr>

      <tr>
        <td class="tableTTH">
          {{ $t("message.help4") }}
          <QuestionMark
            @click.stop.native="$emit('showMsgBoxTwo', 'hi')"
            class="imgS"
          />
        </td>
        <td colspan="3" class="align-middle">
          <a
            class="text-decoration-underline pointer"
            @click="openInNewTab('https://www.deliquidity.com/')"
            >{{ $t("message.dlp23") }}</a
          >
        </td>
      </tr>
      <tr>
        <td class="tableTTH align-middle">{{ $t("message.dlp7") }}</td>
        <td>
          <b-img
            fluid
            src="../../assets/img/card2.png"
            alt="membership card"
            class="borderRadiusS"
          />
        </td>
        <td>
          <b-img
            fluid
            src="../../assets/img/card1.png"
            alt="membership card"
            class="borderRadiusS"
          />
        </td>
        <td>
          <b-img
            fluid
            src="../../assets/img/card0.png"
            alt="membership card"
            class="borderRadiusS"
          />
        </td>
      </tr>
    </tbody>
  </b-table-simple>
</template>

<script>
import QuestionMark from "../Question.vue";
import { openInNewTab } from "../../control/basicSet";

export default {
  name: "MembershipTiers",
  emits: ["showMsgBoxTwo"],
  components: { QuestionMark },
  data() {
    return {
      openInNewTab,
    };
  },
};
</script>
<style scoped>
.border {
  border: 1px solid #dee2e6 !important;
}
.tableHe {
  background-color: #fff !important;
  color: #212529;
}
.tableTTH {
  background-color: #fff !important;
  color: #212529;
}
.tableW {
  width: 26%;
}
.borderRadiusS {
  border-radius: 5px !important;
}
@media screen and (max-width: 768px) {
  .imgS {
    width: 18px;
  }
}

@media screen and (max-width: 460px) {
  .tableW {
    padding: 0.5rem 0.8rem;
  }
  .imgS {
    width: 12px;
  }
}
@media screen and (max-width: 374px) {
  .imgS {
    width: 9px;
  }
}
</style>
