<template>
  <div class="d-flex justify-content-center">
    <div class="imagePadding" :style="data" title="grade"></div>
    <!-- :img-src="require(`@/assets/img/${gradeToImage}`)" -->
  </div>
</template>

<script>
import { useBannerStore } from "../../stores/banner";
export default {
  name: "Front",
  setup() {
    const bannerStore = useBannerStore();
    return { bannerStore };
  },
  data() {
    return {
      data: {
        backgroundImage: `url(${require(`@/assets/img/${this.bannerStore.gradeToImage}`)})`,
      },
    };
  },
};
</script>
