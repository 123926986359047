<template>
  <div class="d-flex justify-content-between px-2">
    <div class="fw-bold">{{ title }}</div>
    <div class="pe-1">{{ text }}</div>
  </div>
</template>
<script>
export default {
  name: "CommonT",
  props: {
    title: String,
    text: String,
  },
};
</script>
