<template>
  <div class="mx-1 forTable">
    <Claimable @showMsgBoxTwo="showMsgBoxTwo" />
    <div class="d-flex justify-content-between px-2 tableMB">
      <div class="fw-bold">{{ $t("message.mef3") }}</div>
      <div>
        <span>{{ contractStore.totalReward }} DLP</span
        ><QuestionMark @click.native="showMsgBoxTwo($t('message.dlpHelp4'))" />
      </div>
    </div>
    <div class="d-flex justify-content-between px-2 align-items-center">
      <div class="fw-bold">
        {{ $t("message.newSecond8") }}
      </div>
      <div>
        <div>
          <span>{{ Number(contractStore.depositD).toFixed(2) }} DLP</span
          ><QuestionMark
            @click.native="showMsgBoxTwo($t('message.dlpHelp5'))"
          />
        </div>
        <span class="iconColor1"
          ><small>
            = $
            {{
              contractStore.USD === null
                ? "0.0000"
                : (contractStore.depositD / contractStore.USD).toFixed(4)
            }}</small
          >
        </span>
      </div>
    </div>
    <CommonT
      class="tableMT mb-4"
      v-if="myPage"
      :title="$t('message.newSecond7')"
      :text="contractStore.nowGrade"
    />
    <CommonT class="tableM" :title="$t('message.newSecond9')" text="180 days" />
    <CommonT
      v-if="myPage"
      :title="$t('message.help3')"
      :text="
        contractStore.onChainGrade != '0'
          ? contractStore.membershipApy[contractStore.onChainGrade - 1] + '%'
          : null
      "
    />
    <CommonT
      class="tableM"
      :title="$t('message.newSecond5')"
      :text="
        contractStore.nowGrade == 'none'
          ? null
          : contractStore.addressToDepositTime
      "
    />
    <CommonT
      :title="$t('message.newSecond6')"
      :text="contractStore.nowGrade == 'none' ? null : contractStore.will"
    />
  </div>
</template>
<script>
import QuestionMark from "../Question.vue";
import CommonT from "./CommonT.vue";
import Claimable from "../Claimable.vue";
import { useContractStore } from "../../stores/contractView";

export default {
  name: "Detail",
  emits: ["showMsgBoxTwo"],
  props: { myPage: Boolean },
  components: { QuestionMark, CommonT, Claimable },

  setup() {
    const contractStore = useContractStore();
    return {
      contractStore,
    };
  },
  methods: {
    showMsgBoxTwo(msg) {
      this.$emit("showMsgBoxTwo", msg);
    },
  },
};
</script>
<style scoped>
.tableMB {
  margin-top: 1.75rem;
  margin-bottom: 1.25rem;
}
.tableMT {
  margin-top: 1.25rem;
  margin-bottom: 2rem;
}
.tableM {
  margin-top: 1.25rem;
  margin-bottom: 2rem;
}
</style>
