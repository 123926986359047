<template>
  <div class="d-flex justify-content-between mb-3">
    <span class="iconColor1">
      {{ $t("message.newThird2") }}
      <span class="iconColor"
        ><strong
          >180<!--({{ waiting }}s)-->
          days.</strong
        ></span
      >
    </span>
    <!-- 멤버쉽 설명 끝 -->
  </div>
</template>

<script>
export default {
  name: "HelpMsg",
};
</script>
